<template>
	<div v-title :data-title="'三特科技-'+channel.name">
		<FHeader></FHeader>
		<article v-if="id==3">
			<FChannelImage :data="channel"></FChannelImage>
			<FRecruit></FRecruit>
		</article>
		<article v-if="id==4">
			<FChannelImage :data="channel"></FChannelImage>
			<FAboutTitle></FAboutTitle>
			<FAbout></FAbout>
		</article>
		<Footer></Footer>
	</div>
</template>

<script>
	import FHeader from "../../components/f/FHeader.vue"
	import FChannelImage from "../../components/f/FChannelImage.vue"
	import FRecruit from "../../components/f/FRecruit.vue"
	import FAboutTitle from "../../components/f/FAboutTitle.vue"
	import FAbout from "../../components/f/FAbout.vue"
	import Footer from "../../components/f/Footer.vue"
	import {getChannel} from "../../api/front";
	export default {
		name: "Index",
		components: {
			FHeader,
			FChannelImage,
			FRecruit,
			FAboutTitle,
			FAbout,
			Footer,
		},
		data() {
			return {
				id:0,
				channel: {}
			}
		},
		watch: {
			$route(route){
				this.$router.go(0); 
			}
		},
		mounted() {
			this.id=this.$route.params.id
			this.queryChannel(this.id)
		},
		beforeRouteUpdate(to, from, next) {
			this.queryChannel(to.params.id)
			next()
		},
		methods: {
			queryChannel(id) {
				// console.log("栏目ID",id)
				getChannel(id).then(data => {
					this.channel = data.data;
					// console.log("栏目详情",this.channel)
				})
			}
		}
	}
</script>

<style scoped>

</style>
