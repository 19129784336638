<template>
	<div id="recruit">
		<div class="postBox" v-for="(item,index) in recruitList" :key="index">
			<div class="name">{{item.name}}</div>
			<div class="info">
				<span class="item">{{item.quantity}}人</span>
				<span class="item">{{item.degree}}</span>
				<span class="item">{{item.workplace}}</span>
			</div>
			<div class="main">
				<div class="request">
					<span class="title">一、任职要求</span>
					<span class="text">{{item.request}}</span>
				</div>
				<div class="treatment">
					<span class="title">二、薪资待遇</span>
					<span class="text">{{item.treatment}}</span>
				</div>
				<div class="content">
					<span class="title">三、工作内容</span>
					<span class="text">{{item.content}}</span>
				</div>
			</div>
		</div>
		<el-pagination
			background
			style="margin: 0px auto;"
			:total="total"
			:current-page="pageNo"
			:page-size="pageSize"
			@current-change="handleCurrentChange"
			layout="total, prev, pager, next , jumper">
		</el-pagination>
	</div>
</template>

<script>
	import {queryRecruitList} from "@/api/front";
	export default {
		name: "Recruit",
		data() {
			return {
				recruitList:[],
				total: 0,
				pageNo: 1,
				pageSize: 3,
			}
		},
		mounted() {
			this.list()
		},
		methods: {
			//改变当前页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.list()
			},
			//查询用户列表
			list() {
				let param={
					name: '',
					page: this.pageNo,
					pageSize: this.pageSize,
				}
				queryRecruitList(param).then(data => {
					console.log(data)
					this.recruitList = data.data.list
					this.total = data.data.total
				}).catch(error => {
					this.$message.error(error)
				})
			},
		},
	}
</script>

<style lang="scss" scoped="scoped">
	#recruit{
		padding: 60px 18% 30px 18%;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		.postBox{
			width: 1000px;
			padding: 30px;
			margin: 0 auto 20px auto;
			border-top: 2px solid #0075FF;
			.name{
				color: #1a86ff;
				font-size: 32px;
				text-align: center;
				margin-bottom: 10px;
			}
			.info{
				display: flex;
				flex-direction: row;
				justify-content: center;
				padding: 0 250px;
				color: #666666;
				.item{
					margin: 0 15px;
				}
			}
			.main{
				.request,.treatment,.content{
					.title{
						margin: 10px 0;
						display: block;
						font-size: 18px;
						color: #2E2E2E;
					}
					.text{
						font-size: 15px;
						color: #666666;
					}
				}
			}
		}
	}
</style>
