<template>
	<div id="footer">
		<div class="mainbox">
			<div class="column">
				<span class="title">关于三特</span>
				<span class="link" v-for="(item,index) in channels" :key="index">{{item.label}}</span>
			</div>
			<div class="column">
				<span class="title">友情链接</span>
				<a class="link" href="http://www.sante.com.cn/" target="_blank">武汉三特索道集团股份有限公司</a>
<!--				<a class="link" href="" target="_blank">唐山启晟信息科技有限公司</a>-->
			</div>
			<div class="column">
				<span class="title">联系我们</span>
				<span class="link">电话：027-87883378</span>
				<span class="link">邮箱：stkjltp@qq.com</span>
				<span class="link">地址：武汉东湖新技术开发区关山一路特1号光谷软件园D1栋1楼108室</span>
			</div>
			<div class="qrcode">
				<!-- <div class="app">
					<img src="../../assets/fImage/qrcode.jpg">
					<span>APP下载</span>
				</div> -->
				<div class="wechat">
					<img src="../../assets/fImage/qrcode.jpg">
					<span>企业微信</span>
				</div>
			</div>
		</div>
		<div class="tipbox">
			<span>版权所有 © 2021-2030 武汉三特索道科技发展有限公司</span>
			<span class="block"></span>
			<!-- <span>备案号：</span> -->
			<a class="link" href="https://beian.miit.gov.cn/" target="_blank">鄂ICP备2021022516号-1</a>
		</div>
	</div>
</template>

<script>
	import {queryByPos} from "../../api/front.js"
	export default {
		name: "Footer",
		data() {
			return {
				channels: [],
			}
		},
		mounted() {
			queryByPos('nav').then(data => {
				this.channels = data.data
			}).catch(error => {

			})
		}
	}
</script>

<style lang="scss" scoped>
	a{
		color: #FFFFFF;
		text-decoration: none;
	}
	a:hover {
		color : #1a86ff ;
	}
	#footer {
		height: 250px;
		width: 100%;
		background-color: #2D2F39;
		color: #FFFFFF;
		font-family: Adobe Heiti Std R;
		.mainbox{
			padding: 30px 15% 20px 15%;
			border-bottom: 2px solid #121215;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			.column{
				max-width: 300px;
				height: 150px;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				.title{
					margin-bottom: 10px;
					font-size: 18px;
					line-height: 27px;
				}
				.link{
					margin-bottom: 5px;
				}
			}
			.qrcode{
				width: 120px;
				display: flex;
				img{
					width: 100px;
					height: 100px;
					margin: 10px 0 0 10px;
				}
				span{
					display: inline-block;
					width: 120px;
					text-align: center;
				};
			}
		}
		.tipbox{
			padding: 12px 15% 16px 15%;
			display: flex;
			flex-direction: row;
			justify-content: center;
			.block{
				display: inline-block;
				width: 200px;
			}
			span{
				display: inline-block;
			};
		}
	}
</style>
