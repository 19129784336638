<template>
	<div id="header">
		<div class="logo">
			<img class="image" src="../../assets/fImage/logo.png">
			<img class="text" src="../../assets/fImage/logoText.png">
		</div>
		<div class="navbar">
			<ul class="menu">
				<li>
					<router-link :to="{name:'fIndex'}" tag="span" class="item" active-class="active" exact>首页</router-link>
				</li>
				<li v-for="(item,index) in channels" :key="index">
					<router-link v-if="item.single" :key="item.id" :to='{path:"/channel_detail/"+item.id}' tag="span" class="item" active-class="active">{{item.label}}</router-link>
					<router-link v-else :key="item.id" :to='{path:"/list_detail/"+item.id}' tag="span" class="item" active-class="active">{{item.label}}</router-link>
					<!-- <ul class="sub" v-if="item.children">
						<li v-for="(sub,sindex) in item.children" :key="sindex">
							<router-link v-if="sub.single" :to='"/channel_detail/"+sub.id'>{{sub.label}}</router-link>
							<router-link v-else :to='"/list/"+sub.id'>{{sub.label}}</router-link>
						</li>
					</ul> -->
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {queryByPos} from "../../api/front.js"
	export default {
		name: "FHeader",
		data() {
			return {
				channels: [],
			}
		},
		mounted() {
			queryByPos('nav').then(data => {
				this.channels = data.data
				// console.log("导航栏",this.channels)
			}).catch(error => {

			})
		},
		methods:{

		} 
	}
</script>

<style lang="scss" scoped>
	ul li {
		padding: 0;
		margin: 0;
		list-style: none;
		display: inline-block;
	}
	#header {
		position: fixed;
		top: 0;
		z-index:5;
		height: 80px;
		width: 100%;
		margin-bottom: 30px;
		background-color: rgba(0,0,0,0.25);
		.logo {
			height: 100px;
			margin-left: 18%;
			display: inline-block;
			float: left;
			.image {
				float: left;
				height: 50px;
				margin: 15px 0;
			}

			.text {
				height: 45px;
				margin: 22.5px 0 12.5px 5px;
			}
		}

		.navbar {
			height: 80px;
			margin-left: 18%;
			display: inline-block;

			.menu {
				padding: 0;
				margin: 0;
				display: flex;
				flex-direction: row;
				justify-content: space-between;

				.item {
					display: inline-block;
					height: 40px;
					width: 100px;
					font-size: 20px;
					color: #FFFFFF;
					line-height: 40px;
					text-align: center;
					margin: 20px 20px;
					border-radius: 10px;
				}
				.item:hover {
					cursor: pointer;
				}
				.item {
					&.active {
						background-color: #1A86FF;
						color: #FFFFFF;
					}
				}
			}
		}
	}
</style>
