<template>
	<div id="banner">
		<el-carousel :autoplay="false" indicator-position="none" arrow="never" :height="bannerHeight + 'px'" :width="bannerWidth + 'px'">
			<el-carousel-item>
				<img :src="url" ref="bannerImg" @load="imgLoad" style="width: 1920px; height: 750px;">
			</el-carousel-item>
		</el-carousel>
	</div>
</template>

<script>
	export default {
		name: "banner",
		props: ['data'],
		data() {
			return {
				/* 轮播图变量 */
				url: '',
				bannerHeight: '', // 图片父容器高度
				bannerWidth: '', //图片父容器宽度
			}
		},
		watch: {
			data: {
				immediate: true, // 这句重要
				handler(val) {
					// console.log("栏目", val)
					this.url=JSON.parse(JSON.stringify(val)).image
				}
			}
		},
		mounted() {
			this.imgLoad();
		},
		methods: {
			imgLoad() {
				this.$nextTick(() => {
					this.bannerHeight = this.$refs.bannerImg.height;
					this.bannerWidth = this.$refs.bannerImg.width;
				})
			},
		},

	}
</script>

<style lang="scss" scoped>
</style>
