import axiosInstance from "./axiosInstance";

// 按照类型信息查询栏目列表
export function queryImageByStatus(status) {
	return axiosInstance.get('/front/querySlideshowListByStatus?status=' + status)
}
// 按照类型信息查询栏目列表
export function queryByPos(type) {
	return axiosInstance.get('/front/queryChannelByType?type=' + type)
}

//获得栏目 by Id
export function getChannel(id) {
	return axiosInstance.get('/front/getChannel?id=' + id)
}

//获得切换列表页 by Id
export function getTab(id) {
	return axiosInstance.get('/front/getTab?id=' + id)
}

//获取已发布的文章列表
export function queryArticleList(param) {
	return axiosInstance.post('/front/queryArticleListByPublish', param)
}
//获取业务景区
export function queryScenicList(param) {
	return axiosInstance.post('/front/queryScenicList', param)
}
//获取软件成果
export function querySoftwareList(param) {
	return axiosInstance.post('/front/querySoftwareList', param)
}
//获取已发布的文章列表
export function queryRecruitList(param) {
	return axiosInstance.post('/front/queryRecruitList', param)
}
//根据id查询文章列表
export function getArticle(id) {
	return axiosInstance.get('/front/getArticle?id=' + id)
}
//根据id查询文章列表
export function getScenic(id) {
	return axiosInstance.get('/front/getScenic?id=' + id)
}

//根据id查询文章列表
export function getSoftware(id) {
	return axiosInstance.get('/front/getSoftware?id=' + id)
}
